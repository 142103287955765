@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

$almost-white: #dfdfdf;
$light-grey: #b1b1b1;
$dark-grey: #111111;
$blue: #17334e;

.clickout {
  z-index: 400;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: black, $alpha: 0.5);
}
.bg {
  background: linear-gradient(
    10deg,
    rgba(184, 184, 184, 1) 0%,
    rgba(218, 218, 218, 1) 30%,
    rgba(223, 223, 223, 1) 70%,
    rgba(184, 184, 184, 1) 100%
  );
}
.inquiry {
  * {
    color: black;
  }
  z-index: 500;
  @include flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  width: 50vh;
  left: 50%;
  transform: translate(-50%);
  margin-top: 55px;
  padding: 20px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 3px solid $dark-grey;
  border-right: 3px solid $dark-grey;
  border-bottom: 3px solid $dark-grey;
  background-color: $almost-white;

  .close {
    user-select: none;
    align-self: flex-end;
    img {
      filter: invert(1);
      cursor: pointer;
      width: 25px;
    }
  }
  h1 {
    user-select: none;
    text-align: center;
    width: 90%;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid steelblue;
  }
  .post-submit {
    @include flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    h1 {
      user-select: text;
      border: none;
      font-size: 2rem;
    }
    .sub {
      font-size: 1.2rem;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  form {
    flex: 1;
    @include flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 90%;
    input:first-child {
      margin-top: 0px;
    }
    input::placeholder,
    .select-opt > select::placeholder,
    textarea::placeholder {
      font-size: 0.85rem;
      letter-spacing: 0.5px;
    }
    input,
    textarea,
    button {
      font-size: 0.85rem;
      outline: none;
      border: none;
      width: 100%;
      max-width: 400px;
      padding: 5px 10px;
      margin-top: 10px;
      border-radius: 5px;
      border: 1px solid $light-grey;
      background-color: white;
    }
    textarea {
      resize: vertical;
      min-height: 60px;
    }
    button {
      color: black;
      cursor: pointer;
      user-select: none;
      font-size: 0.9rem;
      width: auto;
      height: 35px;
      min-height: 35px;
      padding: 4px 20px;
      &:hover {
        border-color: $dark-grey;
      }
      &:active {
        border-color: $dark-grey;
      }
    }
    button[disabled] {
      color: rgb(215, 215, 215);
      border-color: $light-grey;
    }
  }
}

@media (max-width: 600px) {
  .clickout {
    display: none;
    opacity: 0;
  }
  .inquiry {
    position: fixed;
    width: 100vw;
    height: calc(100% - 55px);
    left: 0px;
    transform: unset;
    border-radius: none;
    border: none;
    .close {
      align-self: flex-end;
      img {
        width: 25px;
      }
    }
    h1 {
      width: 100%;
      font-size: 1.6rem;
    }
    form {
      flex: 0;
      width: 100%;
      input,
      textarea {
        font-size: 16px;
      }
    }
  }
}
