@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:100,300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap");

::-webkit-scrollbar {
  position: relative;
  right: 0px;
  top: 100px;
  height: 0;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: darken(white, 25%);
}

::-webkit-scrollbar-thumb {
  background-color: darken(white, 60%);
  border-radius: 5px;
}

:root {
  scrollbar-color: darken(white, 80%) darken(white, 50%) !important;
  scrollbar-width: thin !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: white;
}

body {
  line-height: 1.5rem;
}
html {
  scroll-behavior: smooth;
}
h1 {
  font-family: "Alegreya Sans SC", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 1px;
}
h6 {
  font-family: "Alegreya Sans SC", sans-serif;
  font-size: 3.3rem;
  font-weight: 300;
  letter-spacing: 3px;
}
p {
  font-size: 0.95rem;
  font-family: "Montserrat";
}
li {
  font-family: "Alegreya Sans SC", sans-serif;
}
span {
  font-size: 1.2rem;
  font-family: "Montserrat";
}
a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  min-height: 100vh;
  text-align: center;
  z-index: 1;
  background-color: rgb(218, 218, 218);
}

@media (max-width: 700px) {
  h1 {
    font-size: 1.4rem;
  }
  h6 {
    font-size: 1.6rem;
    font-weight: 400;
  }
  p {
    font-size: 0.85rem;
  }
  span {
    font-size: 1.2rem;
  }
  a {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
}
